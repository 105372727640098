"use client"
import '@mantine/carousel/styles.css';
import Head from 'next/head'
import { Badge, Button, Divider, Paper, Rating, Text, Image as MantineImage, Box, ThemeIcon, Alert, Accordion, Image } from '@mantine/core'
import { ArrowRight, X, Link as LinkIcon, Users, Mouse, Lock, Paint } from 'tabler-icons-react'
import Link from 'next/link'
import { Logo } from '@/components/Logo'
import { bonusSellingPoints, mainSellingPoints, pullFactors, securityConcerns } from '@/static/landingPage'
import React from 'react'
import { DemoTabs } from '@/components/landing/featureTabs/FeatureTabs'
import { TypeAnimation } from 'react-type-animation'
import { useMediaQuery } from '@mantine/hooks'
import { AnyService } from '../../../types/global'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import FinalCTA from '@/components/landing/finalCTA'
import { Carousel } from '@mantine/carousel';

export const PlatformBoxRender = ({ logo, size }: { logo: string, size?: number }) => {
    return (
        <Paper withBorder bg={"#fff"} className="flex aic jcc" radius={(size || 40) / 2} style={{ width: "fit-content" }} p="sm">
            <Image style={{ objectFit: "contain" }} alt={`${logo} logo`} src={`/images/logos/${logo}`} w={size || 40} h={size || 40} />
        </Paper>
    )
}

const Step = ({ vertical, image, step, title, content, inverted }: { vertical: boolean, image: React.ReactNode, step: number, title: string, content: any, inverted?: boolean }) => {
    return (
        <div className="flex jcsb" style={{
            flexDirection: vertical ? 'column-reverse' : inverted ? "row-reverse" : "row",
            gap: 20
        }}>
            {image}
            <div style={{ textAlign: inverted ? "left" : "left" }}>
                <Text c="dimmed" fw={800}>Step #{step}</Text>
                <Text mt={0} fz={25} fw={900}>{title}</Text>
                <div style={{ marginTop: 10 }}>{content}</div>
            </div>
        </div>
    )
}

const Testmonial = ({ image, name, role, review }: { image: string, name: string, role: string, review: string }) => {
    return (
        <Box className='flex jcc'>
            <Paper maw={600} w="100%" className='flex jcc fdc' mt={100} p="xl">
                <Text fw={500} fz={25} maw={500}><Text c="dimmed" fz={25} fw={900} span>❝ </Text>{review}<Text span c={"dimmed"} fw={900} fz={25}> ❞</Text></Text>
                <Divider w={100} my={20} />
                <Box className='flex aic' style={{ gap: 20 }}>
                    <Image src={image} style={{ borderRadius: 500 }} p={5} bg={"var(--mantine-color-blue-6)"} width={90} height={90} />
                    <Box>
                        <Text fz="xl" fw={500}>{name}</Text>
                        <Text c="dimmed" fw={500}>— {role}</Text>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

const logoBar = [
    "Google Analytics",
    "Google Tag Manager",
    "Google Ads",
    "Instagram",
    "Google Search Console"
] as Array<AnyService>

export default function Home() {
    const vertical = useMediaQuery("(max-width: 768px)")
    return (
        <>
            <Head>
                <script id="cookieyes" type="text/javascript" defer src="https://cdn-cookieyes.com/client_data/297a04d6268f2b8095ba3f28/script.js"></script>
                {/* @ts-ignore */}
                <title>AgencyAccess | Credentials Made Simple</title>
                <meta name="facebook-domain-verification" content="xdzr3jk0mgeiw2rdsxe3gxclw6k3sw" />
                <meta name="description" content="Collect your client credentials using our simple, easy to manage platform" />
                {/* @ts-ignore */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" href="/favicon.ico" />

                <meta name="title" content="AgencyAccess | Credentials Made Simple" />
                <meta name="description" content="Collect your client credentials using our simple, easy to manage platform" />
                <meta name="theme-color" content="#488ffe" />
                <meta property="og:locale" content="en_GB" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.agencyaccess.co/" />
                <meta property="og:title" content="AgencyAccess | Credentials Made Simple" />
                <meta property="og:description" content="Collect your client credentials using our simple, easy to manage platform" />
                <meta property="og:image" content="https://storage.googleapis.com/agencyaccess-branding-assets/meta_images/aa_og_image.png" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.agencyaccess.co/" />
                <meta property="twitter:title" content="AgencyAccess | Credentials Made Simple" />
                <meta property="twitter:description" content="Collect your client credentials using our simple, easy to manage platform" />
                <meta property="twitter:image" content="https://storage.googleapis.com/agencyaccess-branding-assets/meta_images/aa_og_image.png" />

            </Head>
            <div style={{ padding: '0 10px' }}>
                <div className='background-grid flex aic jcc' style={{ width: "100%", position: "relative" }}>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            background: "radial-gradient(circle, rgba(255,255,255,0) -50%, rgba(255,255,255,1) 100%)",
                            zIndex: 0
                        }}
                    />
                    <div style={{ zIndex: 10, paddingTop: vertical ? 12 : 40 }} className="flex aic jcc fdc">
                        {
                            !vertical && (
                                <div className="flex aic" style={{ gap: 5 }}>
                                    <Logo width={200} />
                                    {/* <a href="https://www.capterra.com/p/10014213/AgencyAcess/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"> <img width={100} height={50} src="https://assets.capterra.com/badge/1839e03f8599baa98351553c32439704.svg?v=2300964&p=353605" /> </a> */}
                                    {/* <a href="https://www.capterra.com/reviews/353605/AgencyAcess?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"> <img width={100} height={50} src="https://assets.capterra.com/badge/1839e03f8599baa98351553c32439704.svg?v=2300964&p=353605" /> </a> */}
                                </div>
                            )
                        }
                        <Text mt={0} fw={800} fz={{ base: 36, md: 48, lg: 55 }} ta="center">
                            Finally, An Easy way to access clients’
                            <br />
                            <TypeAnimation
                                preRenderFirstString
                                sequence={[
                                    'Google Analytics', 1000,
                                    "Meta Ads", 1000,
                                    'Google Ads', 1000,
                                    "Instagram", 1000,
                                    "Google Tag Manager", 1000,
                                    'Tiktok Ads', 1000,
                                    "Google Search Console", 1000,
                                ]}
                                repeat={Infinity}
                            />
                        </Text>
                        <Text mt={15} ta={"center"} c="dimmed" maw={700} fw={600}>Say goodbye to the hassle of sharing passwords or explaining how to get you access. <br /> With AgencyAccess, you can effortlessly connect to all your client's accounts just by sending 1 link. <br /> <br /> Streamline your agency's workflow and make client onboarding a breeze!</Text>
                        <div className='flex aic jcc' style={{ gap: 10, marginTop: 40, flexWrap: "wrap" }}>
                            {[...logoBar].map((l) => {
                                return <PlatformBoxRender size={30} logo={`${l.toLowerCase().replaceAll(" ", "_")}.png`} />
                            })}
                        </div>
                        <div style={{ marginTop: 30 }} className="flex fdc aic">
                            <Text fw={700} fz="xl" ta="center">Risk Free - Preview it before onboarding your clients</Text>
                            <Link href={"/auth/signup?plan=Free"}>
                                <Button rightSection={<ArrowRight size={20} />} mt={15} size="lg">Get Started Free</Button>
                            </Link>
                            <Text fz={"sm"} c="dimmed" mt={5}>No CC required</Text>

                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", padding: "50px 0" }} className="flex jcc">
                    <div style={{ width: "100%", maxWidth: 1100, padding: 10 }}>

                        <DemoTabs
                            data={mainSellingPoints.map((sp) => ({
                                description: sp.body,
                                name: sp.title,
                            }))}
                        />
                        <div style={{ marginTop: 100, gap: 20 }} className={`flex jcsb ${vertical ? 'fdc' : ''}`}>
                            {bonusSellingPoints.map(bsp => {
                                return (
                                    <div className='flex aic fdc' style={{ gap: 10 }}>
                                        {React.cloneElement(bsp.icon, { size: 60 })}
                                        <Text ta={"center"} fz={"lg"} fw={800}>{bsp.title}</Text>
                                        <Text ta={"center"} c="dimmed">{bsp.body}</Text>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ background: "#fff", padding: vertical ? 10 : 40, marginTop: 100 }} className="flex fdc aic">
                            <Text fw={800} fz="lg" ta={"center"}>ALREADY HAVE AN EASY WAY TO GET ACCESS TO CLIENT ACCOUNTS?</Text>
                            <Divider w={100} mt={10} />
                            <Text ta={"center"} fz={{ base: 32, md: 38, lg: 45 }} fw={800}>Preview Before Onboarding Your Clients</Text>

                            <div style={{ marginTop: 30, gap: 20 }} className={`flex jcsb ${vertical ? 'fdc' : ''}`}>
                                {pullFactors.map((pf, i) => {
                                    return (
                                        <>
                                            <div className='flex' style={{ gap: 10, border: "1px dashed rgba(0,0,0,.1)", borderRadius: 15, padding: 20 }}>
                                                <Text fz={"xl"}>{pf.icon}</Text>
                                                <div className='flex fdc' style={{ gap: 5 }}>
                                                    <Text fw={600} fz="lg">{pf.title}</Text>
                                                    <Text c="dimmed">{pf.body}</Text>
                                                </div>
                                            </div>

                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", padding: "50px 0", background: "#f1f1f1" }} className="flex jcc">
                    <div style={{ width: "100%", maxWidth: 1100, padding: 10 }}>
                        <div className='flex aic fdc'>
                            <Badge color={"red"} size="xl">The Problem</Badge>
                            <Text ta={"center"} fz={{ base: 32, md: 38, lg: 45 }} fw={800}>Does this look like an agency you would work with or buy from?</Text>
                            <div style={{ boxShadow: "0px 10px 10px rgba(0,0,0,.1)", borderRadius: 15, overflow: "hidden", marginTop: 30 }}>
                                <MantineImage src={"/images/landing_page/the_problem.png"} width={"100%"} style={{ maxWidth: 800 }} />
                            </div>
                            <div style={{ marginTop: 0, width: "100%", gap: vertical ? 0 : 30 }} className={`flex jcc ${vertical ? 'fdc' : ''}`}>
                                {securityConcerns.map((sc) => {
                                    return (
                                        <div style={{ flex: 1 }} className="flex aic jcc fdc">
                                            <div>
                                                <Divider w={1} style={{ borderWidth: 2 }} variant="dashed" orientation="vertical" h={100} />
                                            </div>
                                            <Paper bg={"#fff"} radius="lg" p={30} style={{ flex: 1 }} maw={500}>
                                                <div className='flex' style={{ gap: 15 }}>
                                                    <ThemeIcon color={"red"} variant="light" size={"xl"}>
                                                        <X size={28} />
                                                    </ThemeIcon>
                                                    <div>
                                                        <Text mb={5} fz={"lg"} fw={800}>{sc.title}</Text>
                                                        <Text>{sc.body}</Text>
                                                    </div>
                                                </div>
                                            </Paper>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='flex aic fdc' style={{ padding: "50px 0", marginTop: 30 }}>
                            <Text fz={25} fw={800} maw={500} ta="center">How much business are you losing due to unprofessionalism and inefficiency?</Text>
                            <Link href={"/auth/signup"}>
                                <Button rightSection={<ArrowRight size={20} />} mt={15} size="lg">Try AgencyAcess instead</Button>
                            </Link>
                            <Text fz={"sm"} c="dimmed" mt={5}>No CC required</Text>
                            <Box mt={30}>
                                <ReactCompareSlider
                                    itemOne={<ReactCompareSliderImage src="/images/landing_page/beforeAfter/before.png" srcSet="/images/landing_page/beforeAfter/before.png" alt="Image one" />}
                                    itemTwo={<ReactCompareSliderImage src="/images/landing_page/beforeAfter/after.png" srcSet="/images/landing_page/beforeAfter/after.png" alt="Image two" />}
                                    style={{ width: "100%", maxWidth: 700 }}
                                />
                            </Box>
                            {/* <Skeleton w={"100%"} style={{ aspectRatio: "9 / 10", marginTop: 20, maxWidth: 400 }} /> */}
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", padding: "50px 0", background: "#fff" }} className="flex jcc">
                    <div style={{ width: "100%", maxWidth: 900, padding: vertical ? 0 : 10 }}>
                        <div className='flex fdc' style={{ gap: 50 }}>
                            <Step
                                vertical={!!vertical}
                                image={<Image alt='Connections' src={"/images/landing_page/graphics/connections.png"} maw={160 * 2} w={"100%"} />}
                                title='Connect your accounts to AgencyAccess'
                                content={
                                    <div>
                                        <Text c={"dimmed"} fw={600}>{"Kickstart the transformation of your agency's workflow by seamlessly connecting your account to AgencyAccess."}</Text>
                                        <Button mt={10} rightSection={<ArrowRight size={20} />}>Try Agencyaccess for free</Button>
                                        <Accordion mt={10} variant={"filled"}>
                                            <Accordion.Item value='default'>
                                                <Accordion.Control icon={<Text>🔐 </Text>}>
                                                    <strong>How do we make sure we keep your data secure?</strong>
                                                </Accordion.Control>
                                                <Accordion.Panel>
                                                    We use industry-standard OAuth technology to ensure your data remains safe and protected throughout the process. Say goodbye to sharing passwords or sensitive credentials
                                                </Accordion.Panel>
                                            </Accordion.Item>
                                        </Accordion>
                                        {/* <Alert color={"gray"} mt={10}>
                                            <strong>🔐 - How do we make sure we keep your data secure?</strong> <br /> We use industry-standard OAuth technology to ensure your data remains safe and protected throughout the process. Say goodbye to sharing passwords or sensitive credentials
                                        </Alert> */}
                                    </div>
                                }
                                step={1}
                            />
                            <Step
                                vertical={!!vertical}
                                image={<Image alt='Branding' src={"/images/landing_page/graphics/branding.png"} maw={160 * 2} style={{ objectFit: "contain" }} w={"100%"} />}
                                title='Add your own look and feel'
                                content={
                                    <>
                                        <div className='flex fdc' style={{ gap: 30 }}>
                                            <div className='flex' style={{ gap: 10 }}>
                                                <ThemeIcon c="black" size={"lg"} color="gray" variant={"light"}>
                                                    <LinkIcon style={{ minWidth: 25 }} />
                                                </ThemeIcon>
                                                <div>
                                                    <Text fw={700} fz={"lg"}>
                                                        Create a custom branded access link
                                                    </Text>
                                                    <Text c="dimmed">
                                                        Make your agency's mark with AgencyAccess. You can effortlessly add your own logo and choose your brand's primary and secondary colors. Create a fully branded onboarding experience that resonates with your agency's identity
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className='flex' style={{ gap: 10 }}>
                                                <ThemeIcon c="black" size={"lg"} color="gray" variant={"light"}>
                                                    <Users style={{ minWidth: 25 }} />
                                                </ThemeIcon>
                                                <div>
                                                    <Text fw={700} fz={"lg"}>
                                                        Personalise to recipients
                                                    </Text>
                                                    <Text c="dimmed">
                                                        Take personalization to the next level. Customize the onboarding experience for each client by including their company name, first name, and other personalized elements on the custom landing page. It's all about making each client feel special
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className='flex' style={{ gap: 10 }}>
                                                <ThemeIcon c="black" size={"lg"} color="gray" variant={"light"}>
                                                    <Mouse style={{ minWidth: 25 }} />
                                                </ThemeIcon>
                                                <div>
                                                    <Text fw={700} fz={"lg"}>
                                                        Custom domain hosting
                                                    </Text>
                                                    <Text c="dimmed">
                                                        Elevate your agency's professionalism with custom domain hosting. Use your agency's domain to host the onboarding experience. It's a seamless way to impress clients and enhance your agency's online presence.
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                        <Accordion mt={20} variant={"filled"}>
                                            <Accordion.Item value='default'>
                                                <Accordion.Control icon={<Text>ℹ️ </Text>}>
                                                    <strong>Can I save different customizations for different clients?</strong>
                                                </Accordion.Control>
                                                <Accordion.Panel>
                                                    Yes, you can! AgencyAccess allows you to save different customizations for different clients. Tailor each onboarding link to match the unique preferences and branding of your clients. It's all about creating a personalized experience.
                                                </Accordion.Panel>
                                            </Accordion.Item>
                                        </Accordion>
                                        {/* <Alert color={"gray"} mt={20}>
                                            <div className='flex' style={{ gap: 10 }}>
                                                ℹ️
                                                <Text>
                                                    <strong>
                                                        Can I save different customizations for different clients?
                                                    </strong>
                                                    <br />
                                                    Yes, you can! AgencyAccess allows you to save different customizations for different clients. Tailor each onboarding link to match the unique preferences and branding of your clients. It's all about creating a personalized experience.
                                                </Text>
                                            </div>
                                        </Alert> */}
                                    </>
                                }
                                step={2}
                                inverted
                            />

                            <div>
                                <Divider variant={"dashed"} />
                                <div style={{ margin: "10px 0" }}>
                                    <Alert p={"lg"} color={"gray"}>
                                        <div className='flex' style={{ gap: 10 }}>
                                            <Lock size={30} style={{ minWidth: 30 }} />
                                            <div>
                                                <Text fw={700} fz={"lg"}>Your Data, Your Security</Text>
                                                <Text>AgencyAccess employs industry-standard OAuth technology, which is known for its robust security and privacy protocols. OAuth is designed in a way that grants access to specific resources without exposing sensitive data like passwords. This means we never have access to your sensitive information, ensuring your data remains confidential and secure</Text>
                                            </div>
                                        </div>
                                    </Alert>
                                </div>
                                <Divider variant={"dashed"} />
                            </div>

                            <Step
                                vertical={!!vertical}
                                image={<Image alt='Access' src={"/images/landing_page/graphics/access.png"} maw={160 * 2} w={"100%"} />}
                                title='Preview and send to clients'
                                content={
                                    <div>
                                        <Text c={"dimmed"} fw={600}>{"After creating a customized onboarding link, take a moment to preview how it will appear to your clients before finalizing it. Once you're satisfied, you can choose to send the link via email directly through AgencyAccess or simply copy the link to share it through your preferred communication channel. We give you the flexibility to send it your way"}</Text>
                                    </div>
                                }
                                step={3}
                            />
                        </div>

                        <Carousel>
                            <Carousel.Slide>
                                <Testmonial
                                    image='/images/landing_page/bernt.jpeg'
                                    name='Bernt Muurling'
                                    review={`AgencyAccess has revolutionized the way we onboard clients. The customization options are fantastic, and the security measures put our minds at ease. It's a must-have tool for agencies.`}
                                    role='Co-founder at Taskforce Agency'
                                />
                            </Carousel.Slide>
                            <Carousel.Slide>
                                <Testmonial
                                    image='/images/landing_page/ruben.jpeg'
                                    name='Ruben Runneboom'
                                    review={`Using AgencyAccess has significantly improved our efficiency. The link preview feature is a game-changer, and the fact that they don't access our data shows their commitment to privacy.`}
                                    role='Co-founder at Taskforce Agency'
                                />
                            </Carousel.Slide>
                            <Carousel.Slide>
                                <Testmonial
                                    image='images/landing_page/kevin.webp'
                                    name='Stefan Ionita'
                                    review='AgencyAccess made our onboarding faster and easier. Saves us hours a week, and our clients love it!'
                                    role='Dagami Marketing'
                                />
                            </Carousel.Slide>
                        </Carousel>
                        <Divider my={100} variant="dashed" />
                        <div>
                            <div className='flex aic jcc fdc' style={{ gap: 10 }}>
                                <Paint size={70} />
                                <Text fw={900} fz={{ base: 32, md: 38, lg: 45 }} ta="center">Want to change your branding? All links are updated automatically</Text>
                                <Text fz={"xl"} c="dimmed" ta={"center"}>Did you recently change your branding, or want to add a new logo? AgencyAccess automatically updates all links that use custom branding.</Text>
                            </div>

                            <div className={`flex jcsb ${vertical ? 'fdc' : ''}`} style={{ marginTop: 60, gap: 30 }}>
                                <Box w={vertical ? undefined : 400}>
                                    <Image alt='Support' src={"/images/landing_page/graphics/support.png"} w={118 * 2} h={88.5 * 2} />
                                    <Text fw={700} mt={5} fz="xl">Always Here for You</Text>
                                    <Text fz={"md"} c="dimmed">We're committed to your success. Our 24/7 support team is ready to assist you whenever you need help. Whether it's a question, concern, or product suggestion, we're just a message away. Your satisfaction is our priority.</Text>
                                    <Link href={"mailto:support@agencyaccess.co"}>
                                        <Button variant={"subtle"} rightSection={<ArrowRight size={20} />} mt={5}>Ask a Question</Button>
                                    </Link>
                                </Box>
                                <Box w={vertical ? undefined : 400}>
                                    <Image alt='Reviews' src={"/images/landing_page/graphics/reviews.png"} w={118 * 2} h={88.5 * 2} />
                                    <Text fw={700} mt={5} fz="xl">Your Ideas Matter</Text>
                                    <Text fz={"md"} c="dimmed">We value your feedback. If you have product suggestions or feature requests, please let us know. We're constantly working to enhance AgencyAccess and make it even better for you. Your input helps shape the future of our platform.</Text>
                                </Box>
                            </div>
                        </div>
                        <div style={{ marginTop: 100, background: "#f1f1f1", width: "100%", padding: "50px 20px", borderRadius: 10, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} className="flex aic jcc fdc">
                            <Logo width={225} />
                            <div className='flex aic' style={{ gap: 15, marginTop: 10 }}>
                                <Rating readOnly value={5} />
                                <Divider orientation="vertical" h={25} />
                                <Text fw={600}>100+ Reviews</Text>
                            </div>
                            <div style={{ marginTop: 40 }} className="flex aic jcc fdc">
                                <Text fz={30} ta="center" fw={700}>This Is Why Agencies Love Agencyaccess</Text>
                                <div style={{ marginTop: 30, gap: 25 }} className={`flex ${vertical ? 'fdc' : ''}`}>
                                    {[
                                        "AgencyAccess has revolutionized the way we onboard clients. The customization options are fantastic, and the security measures put our minds at ease. It's a must-have tool for agencies.",
                                        "Using AgencyAccess has significantly improved our efficiency. The link preview feature is a game-changer, and the fact that they don't access our data shows their commitment to privacy.",
                                        "AgencyAccess has been a productivity booster for our agency. The branding options help us stand out, and the ease of sending links is fantastic. It's become an essential part of our workflow."
                                    ].map((review) => {
                                        return <Text ta={"center"}>"{review}"</Text>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div style={{ background: "#f9f9f9", width: "100%", padding: "30px 15px", gap: 50 }} className={`flex aic jcc ${vertical ? 'fdc' : ''}`}>
                            {/* <Skeleton style={{ height: 100 }} /> */}
                            <Image alt="Dagami logo" src={"/images/landing_page/companies/Dagami.png"} w={100} h={100} style={{ objectFit: "contain", filter: "grayscale(100%)" }} />
                            {/* <Skeleton style={{ height: 100 }} /> */}
                            <Image alt="Dagami logo" src={"/images/landing_page/companies/growyourecom.png"} w={160} h={100} style={{ objectFit: "contain", filter: "grayscale(100%) invert(1)", }} />
                            <Image alt="Taskforce Agency logo" src={"/images/landing_page/companies/taskforce.png"} w={160} h={100} style={{ objectFit: "contain", filter: "grayscale(100%) invert(0)", }} />
                            {/* <Skeleton style={{ height: 100 }} /> */}
                        </div>
                        <FinalCTA />
                    </div>

                </div>
            </div>
        </>
    )
}
