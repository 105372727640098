import React, { useState, useRef } from 'react';
import { Container, Grid, UnstyledButton, Text, Box, rem, Title, Badge, Paper, useMantineTheme, Divider, Flex, Skeleton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const videos = [
    "link",
    "onboarding",
    "branded_onboarding"
]

export function DemoTabs({ data }: {
    data: Array<{
        name: string,
        description: string,
    }>,
}) {
    const [shouldAnimate, setShouldAnimate] = useState(false);
    // @ts-ignore
    const animationTimeout = useRef();
    const [active, setActive] = useState(0);
    const controlSize = 125;
    const vertical = useMediaQuery("(max-width: 768px)")
    const handleActiveChange = (index: number) => {
        setActive(index);
        if (index !== active) {
            setShouldAnimate(true);
            window.clearTimeout(animationTimeout.current);
            // @ts-ignore
            animationTimeout.current = window.setTimeout(() => setShouldAnimate(false), 500);
        }
    };

    const controls = data.map((item, index) => (
        <UnstyledButton
            key={item.name}
            onClick={() => handleActiveChange(index)}
            data-active={index === active || undefined}
            className={"control"}
        >
            {/* {item.icon} */}
            <div>
                <Text className={"controlTitle"}>{item.name}</Text>
                <Text c="dimmed" fz="sm" className={"controlDescription"}>
                    {item.description}
                </Text>
            </div>
        </UnstyledButton>
    ));


    return (
        <div className={`flex ${vertical ? 'fdc' : ''}`} style={{ gap: 20 }}>
            <div style={{ flex: 1 }} >
                <video controls={false} muted webkit-playsinline playsInline autoPlay loop src={`/images/landing_page/video/${videos[active]}.mp4`} style={{ width: "100%", aspectRatio: "16 / 9", borderRadius: 10, boxShadow: "0px 0px 10px rgba(0,0,0,.2)" }}></video>
            </div>
            <div className='flex fdc' style={{ gap: 25 }}>
                <div>
                    <Container p={0} className="flex aic fdc">
                        <Box maw={vertical ? undefined : 400}>
                            <div className={"controls"}>
                                <Box
                                    className={"controlsIndicator"}
                                    style={{
                                        height: `${controlSize}px`,
                                        transform: `translateY(${(controlSize * active)}px)`,
                                    }}
                                />
                                {controls}
                            </div>
                        </Box>
                    </Container>
                </div>
            </div>
        </div>
    );
}