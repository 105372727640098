import { CircleNumber1, CircleNumber2, CircleNumber3, Clock, Lock, Tag, Tie } from "tabler-icons-react"

export const mainSellingPoints = [
    { title: "Single-Link Account Access", body: "Save hours by requesting access to different accounts in just one link." },
    { title: "Seamless Client Onboarding", body: "Eliminate the need for password sharing and complicated explanations. Simplify client onboarding with a streamlined and efficient process." },
    { title: "Branded Onboarding Expierence", body: "Create a fully customized onboarding experience, showcasing your agency's logo and branding to impress clients and enhance professionalism." }
]

export const bonusSellingPoints = [
    {
        title: "Time Efficiency",
        body: `Save valuable time by instantly connecting to client accounts without the hassles of password sharing or lengthy setup procedures.`,
        icon: <Clock />
    },
    {
        title: "Enhanced Security",
        body: `Ensure the security and privacy of both your agency and clients' accounts, meeting GDPR compliance standards and safeguarding sensitive data.`,
        icon: <Lock />
    },
    {
        title: "Professional Impressions",
        body: `Create a strong first impression with clients by presenting a branded onboarding process, highlighting your agency's expertise and commitment to excellence.`,
        icon: <Tie />
    },
]

export const pullFactors = [
    {
        title: "Create your own branded onboarding link",
        body: `With AgencyAccess, you can take control of your onboarding process. Create your personalized onboarding link, tailor it to your agency's needs, and experience a hassle-free way to access client accounts.`,
        icon: <CircleNumber1 />
    },
    {
        title: "Preview before sending to clients",
        body: `Don't leave anything to chance. Preview the onboarding experience your clients will have before sending the link. Ensure it aligns with your agency's branding and impresses clients from the start`,
        icon: <CircleNumber2 />
    },
    {
        title: "14 days to get your money back",
        body: `We're confident you'll love the efficiency and security AgencyAccess provides. Try it risk-free for 14 days, and if you're not completely satisfied, we'll refund your money, no questions asked`,
        icon: <CircleNumber3 />
    }
]

export const securityConcerns = [
    {
        title: "Unprofessional & Inefficient",
        body: "Email requests and manual setup processes waste time and don't leave a good impression on clients. AgencyAccess solves this by providing a secure and efficient solution, eliminating the need for such unprofessional practices"
    },
    {
        title: "Security Concerns",
        body: "Agencies using these methods expose themselves to inefficiencies and security risks. AgencyAccess uses cutting-edge OAuth technology to provide a secure and efficient solution. We never save sensitive data like user passwords, prioritizing your clients' data security"
    }
]