import { Button, Text } from '@mantine/core'
import Link from 'next/link'
import React from 'react'

export default function FinalCTA({ mt }: { mt?: number }) {
    return (
        <div style={{ marginTop: mt || 100 }} className="flex aic jcc fdc">
            <Text fw={900} fz={{ base: 32, md: 38, lg: 45 }} ta="center">You're 5 Minutes Away from Unlocking the Future of Client Onboarding</Text>
            <Text c="dimmed" fz={22} ta="center">Get started for free! Streamline your workflow, enhance security, and leave a lasting professional impression on your clients.</Text>
            <div className='flex jcc aic fdc' style={{ gap: 15, marginTop: 40 }}>
                {[
                    "Simplify onboarding, save time, grow your business.",
                    "Protect data, build trust with advanced security.",
                    "Impress clients with easy of use and custom branding"
                ].map((sp) => {
                    return <Text ta={"center"} fw={600}>✅ {sp}</Text>
                })}
            </div>
            <Link href={"/auth/signup?plan=Free"}>
                <Button mt={20} size="lg">Get Started Free</Button>
            </Link>
        </div>
    )
}
